import PropTypes from "prop-types"
import React from "react"

import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import logo from "../images/logo.svg";
import logo_brown from "../images/logo-brown.svg";

const Header = ({ siteTitle, intl }) => (

    <header className="header">
      <div className="header--sidebar">
        <div className="header--sidebar__logo">
          <Link className="header--sidebar__logo-link" to="/">
            <img className="header--sidebar__logo-color" src={logo} alt={siteTitle + " logo"} />
            <img className="header--sidebar__logo-brown" src={logo_brown} alt={siteTitle + " brown logo"} />
          </Link>
        </div>
        <div className="header--sidebar__social-links">
          <ul>
            <li>
              <a href="https://www.instagram.com/ol2dis/" target="_blank" rel="noopener noreferrer">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.375 10C13.375 8.125 11.875 6.625 10 6.625C8.125 6.625 6.625 8.125 6.625 10C6.625 11.875 8.125 13.375 10 13.375C11.875 13.375 13.375 11.875 13.375 10ZM15.125 10C15.125 12.875 12.875 15.125 10 15.125C7.125 15.125 4.875 12.875 4.875 10C4.875 7.125 7.125 4.875 10 4.875C12.875 4.875 15.125 7.125 15.125 10ZM16.5 4.625C16.5 5.375 16 5.875 15.25 5.875C14.5 5.875 14 5.375 14 4.625C14 3.875 14.5 3.375 15.25 3.375C16 3.375 16.5 4 16.5 4.625ZM10 1.75C8.5 1.75 5.375 1.625 4.125 2.125C3.25 2.5 2.5 3.25 2.25 4.125C1.75 5.375 1.875 8.5 1.875 10C1.875 11.5 1.75 14.625 2.25 15.875C2.5 16.75 3.25 17.5 4.125 17.75C5.375 18.25 8.625 18.125 10 18.125C11.375 18.125 14.625 18.25 15.875 17.75C16.75 17.375 17.375 16.75 17.75 15.875C18.25 14.5 18.125 11.375 18.125 10C18.125 8.625 18.25 5.375 17.75 4.125C17.5 3.25 16.75 2.5 15.875 2.25C14.625 1.625 11.5 1.75 10 1.75ZM20 10V14.125C20 15.625 19.5 17.125 18.375 18.375C17.25 19.5 15.75 20 14.125 20H5.875C4.375 20 2.875 19.5 1.625 18.375C0.625 17.25 0 15.75 0 14.125V10V5.875C0 4.25 0.625 2.75 1.625 1.625C2.875 0.625 4.375 0 5.875 0H14.125C15.625 0 17.125 0.5 18.375 1.625C19.375 2.75 20 4.25 20 5.875V10Z" fill="#ffffff"/></svg>
              </a>
            </li>
            <li>
              <a href="https://fb.me/ol2dis" target="_blank" rel="noopener noreferrer">
                <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.075 20H6.7375V10.875H9.8L10.2625 7.325H6.7375V5.05C6.7375 4.025 7.025 3.325 8.5 3.325H10.3875V0.1375C10.0625 0.0999999 8.95 0 7.65 0C4.925 0 3.075 1.6625 3.075 4.7V7.325H0V10.875H3.075V20Z" fill="#ffffff"/></svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="header--nav">
        <ul>
          <li>
            <Link to="/about-us/"><FormattedMessage id="headerAboutUs" /></Link>
          </li>
          <li>
            <Link to="/portfolio/"><FormattedMessage id="headerPortfolio" /></Link>
          </li>
          <li>
            <Link to="/services/"><FormattedMessage id="headerServices" /></Link>
          </li>
          <li>
            <Link to="/contacts/"><FormattedMessage id="headerContacts" /></Link>
          </li>
        </ul>
      </div>
    </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default injectIntl(Header)
